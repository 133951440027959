<template>
  <div style="padding-bottom: 50px">
    <header-title :title="type == 'system' ? '登录页配置' : '首页配置'"></header-title>
    <div>
      <rs-form :model="itemForm" label-width="140px" style="width: 70%" :show-message="false" :rules="rules" ref="loginForm" v-if="type == 'system'">
        <rs-form-item label="登录页标题：" prop="customLoginTitle">
          <div class="flexCls">
            <rs-input v-model="itemForm.customLoginTitle" maxlength="10" placeholder="请输入登录页标题" style="width: 80%"></rs-input>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customLoginTitle.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
        </rs-form-item>

        <rs-form-item label="登录页背景图：" class="form-item" prop="loginImgType">
          <div class="flexCls" style="margin: 8px 0">
            <rs-radio-group v-model="itemForm.loginImgType">
              <rs-radio :label="'0'">默认</rs-radio>
              <rs-radio :label="'1'">自定义</rs-radio>
            </rs-radio-group>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customLoginBgimg.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          <rs-upload
            v-if="itemForm.loginImgType =='1'"
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :limit="1"
            :on-success="handleAvatarSuccess.bind(this, 'customLoginBgimg')"
            :before-upload="beforeAvatarUpload.bind(this, 'customLoginBgimg')"
          >
            <img v-if="itemForm.customLoginBgimg" :src="itemForm.customLoginBgimg" class="avatar" />
            <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          </rs-upload>

          <p v-if="itemForm.loginImgType == '1'" class="uploadTip">仅支持上传1张.png、jpg、.jpeg图片，图片尺寸 475x535，大小不超过10M</p>
          <div class="defaultImg" v-else>
            <img :src="itemForm.customLoginBgDefaultImg" />
          </div>
        </rs-form-item>

        <rs-form-item label="登录页LOGO图：" class="form-item" prop="logoImgType">
          <div class="flexCls" style="margin: 8px 0">
            <rs-radio-group v-model="itemForm.logoImgType">
              <rs-radio :label="'0'">默认</rs-radio>
              <rs-radio :label="'1'">自定义</rs-radio>
            </rs-radio-group>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customLoginLogo.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          <rs-upload
            v-if="itemForm.logoImgType == '1'"
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess.bind(this, 'customLoginLogo')"
            :before-upload="beforeAvatarUpload.bind(this, 'customLoginLogo')"
          >
            <img v-if="itemForm.customLoginLogo" :src="itemForm.customLoginLogo" class="avatar" />
            <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          </rs-upload>
          <p v-if="itemForm.logoImgType == '1'" class="uploadTip">仅支持上传1张.png、jpg、.jpeg图片，图片尺寸50x50，大小不超过3M</p>
          <div class="defaultImg" v-else>
            <img :src="itemForm.customLoginDefaultLogo" />
          </div>
        </rs-form-item>
        <rs-form-item label="备案/许可证号：" class="form-item" prop="baNo">
          <rs-input v-model="itemForm.baNo" maxlength="128" placeholder="备案/许可证号" style="width: 80%"></rs-input>
          <rs-input v-model="itemForm.baUrl" maxlength="256" placeholder="备案链接地址" style="margin-top: 15px; width: 80%"></rs-input>
        </rs-form-item>
      </rs-form>
      <rs-form :model="itemForm1" label-width="160px" style="width: 70%" :show-message="false" :rules="rules1" ref="homeForm" v-if="type == 'home'">
        <rs-form-item label="首页标题：" class="form-item" prop="customIndexTitle">
          <div class="flexCls">
            <rs-input v-model="itemForm1.customIndexTitle" placeholder="请输入首页标题" maxlength="10"></rs-input>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customIndexTitle.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
        </rs-form-item>
        <rs-form-item label="首页LOGO：" class="form-item" prop="logoImgType1">
          <div class="flexCls" style="margin: 8px 0">
            <rs-radio-group v-model="itemForm1.logoImgType1">
              <rs-radio :label="'0'">默认</rs-radio>
              <rs-radio :label="'1'">自定义</rs-radio>
            </rs-radio-group>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customIndexLogo.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          <rs-upload
            v-if="itemForm1.logoImgType1 == '1'"
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess.bind(this, 'customIndexLogo')"
            :before-upload="beforeAvatarUpload.bind(this, 'customIndexLogo')"
          >
            <img v-if="itemForm1.customIndexLogo" :src="itemForm1.customIndexLogo" class="avatar" />
            <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          </rs-upload>
          <p v-if="itemForm1.logoImgType1 == '1'" class="uploadTip">仅支持上传1张.png、.jpg、.jpeg图片，图片尺寸62x45，大小不超过3M</p>
          <div class="defaultImg" v-else>
            <img :src="itemForm1.customIndexDefaultLogo" />
          </div>
        </rs-form-item>
        <rs-form-item label="浏览器系统标题：" class="form-item" prop="customHtmlHeadTitle">
          <div class="flexCls">
            <rs-input v-model="itemForm1.customHtmlHeadTitle" placeholder="请输入首页标题" maxlength="10"></rs-input>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customHtmlHeadTitle.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
        </rs-form-item>
        <rs-form-item label="浏览器系统图标：" class="form-item" prop="browserLogoType">
          <div class="flexCls" style="margin: 8px 0">
            <rs-radio-group v-model="itemForm1.browserLogoType">
              <rs-radio :label="'0'">默认</rs-radio>
              <rs-radio :label="'1'">自定义</rs-radio>
            </rs-radio-group>
            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customHtmlHeadLogo.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          
          <rs-upload
            v-if="itemForm1.browserLogoType == '1'"
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess.bind(this, 'customHtmlHeadLogo')"
            :before-upload="beforeAvatarUpload.bind(this, 'customHtmlHeadLogo')"
          >
            <img v-if="itemForm1.customHtmlHeadLogo" :src="itemForm1.customHtmlHeadLogo" class="avatar" />
            <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          </rs-upload>
          <p v-if="itemForm1.browserLogoType == '1'" class="uploadTip">仅支持上传1张.png、jpg、.jpeg图片，图片尺寸270x190，大小不超过3M</p>
          <div class="defaultImg" v-else>
            <img :src="itemForm1.customHtmlHeadDefaultLogo" />
          </div>
        </rs-form-item>
        <rs-form-item label="工作台-商户小程序：" class="form-item" prop="customMerchantImg" v-if="pageType=='system'">
          <div class="flexCls">
            <rs-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess.bind(this, 'customMerchantImg')"
              :before-upload="beforeAvatarUpload.bind(this, 'customMerchantImg')"
            >
              <img v-if="itemForm1.customMerchantImg" :src="itemForm1.customMerchantImg" class="avatar" />
              <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
              <div class="delCls" v-if="itemForm1.customMerchantImg">
                <i class="rs-icon-delete" @click.stop="delApplet('customMerchantImg')"></i>
              </div>
            </rs-upload>

            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customMerchantImg.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          <p style="font-size: 12px" class="uploadTip">仅支持上传1张.png、jpg、.jpeg图片，图片尺寸125x125，大小不超过3M</p>
        </rs-form-item>
        <rs-form-item label="工作台-会员小程序：" class="form-item"  v-if="pageType=='system'">
          <div class="flexCls">
            <rs-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess.bind(this, 'customAppletVipImg')"
              :before-upload="beforeAvatarUpload.bind(this, 'customAppletVipImg')"
            >
              <img v-if="itemForm1.customAppletVipImg" :src="itemForm1.customAppletVipImg" class="avatar" />
              <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
              <div class="delCls" v-if="itemForm1.customAppletVipImg">
                <i class="rs-icon-delete" @click.stop="delApplet('customAppletVipImg')"></i>
              </div>
            </rs-upload>

            <rs-tooltip placement="top" style="margin-left: 12px">
              <div slot="content" class="imgBox">
                <img src="~@/assets/images/customAppletVipImg.png" />
              </div>
              <i class="rs-icon-question"></i>
            </rs-tooltip>
          </div>
          <p style="font-size: 12px" class="uploadTip">仅支持上传1张.png、jpg、.jpeg图片，图片尺寸125x125，大小不超过3M</p>
        </rs-form-item>
      </rs-form>
    </div>
    <div class="edit-form button-box" style="text-align: right">
      <rs-button size="small" @click="jumpBack(false)">取消</rs-button>
      <rs-button size="small" @click="save" type="primary">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Api from '@/api/imgSetter.js'
import headerTitle from '@/components/header'
export default {
  components: { headerTitle },
  data() {
    return {
      type: 'system',
      pageType:'system',
      title: '',
      saveLoading: false,
      itemForm: {
        customLoginTitle: '',
        loginImgType: '0',
        logoImgType: '0',
        customLoginLogo: '',
        customLoginBgimg: '',
        customLoginBgDefaultImg: 'https://image.mklsoft-test.com/g1/M00/00/02/CsIMZ2DlRQWALaZzAEnnVqwkUu8361.png',
        customLoginDefaultLogo: 'https://image.mklsoft-test.com/g1/M00/00/04/CsIMaGGu-s-AeMOYAAI4qnaAWcI646.jpg',
        baNo: '',
        baUrl: ''
      },
      itemForm1: {
        customIndexTitle: '',
        logoImgType1: '0',
        customIndexLogo: '',
        customHtmlHeadTitle: '',
        browserLogoType: '0',
        customMerchantImg: '',
        customIndexDefaultLogo: 'https://image.mklsoft-test.com/g1/M00/00/04/CsIMaGGu-oqAAM49AABh20r0wVs379.png',
        customHtmlHeadDefaultLogo: 'https://image.mklsoft-test.com/g1/M00/00/04/CsIMaGGu-n6ABwFKAABh20r0wVs407.png',
        customAppletVipImg: ''
      },
      rules: {
        customLoginTitle: [{ required: true, message: '请输入登录页标题', trigger: 'blur' }],
        loginImgType: [{ required: true, message: '请上传登录背景图', trigger: 'blur' }],
        logoImgType: [{ required: true, message: '请上传登录页LOGO图', trigger: 'blur' }],
        baNo: [{ required: true, message: '请输入备案/许可证', trigger: 'blur' }]
      },
      rules1: {
        customIndexTitle: [{ required: true, message: '请输入首页标题', trigger: 'blur' }],
        logoImgType1: [{ required: true, message: '请上传首页logo', trigger: 'blur' }],
        customHtmlHeadTitle: [{ required: true, message: '请输入浏览器系统图标', trigger: 'blur' }],
        browserLogoType: [{ required: true, message: '请上传浏览器系统图标', trigger: 'blur' }]
      },
      uploadUrl: ''
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.pageType = this.$route.query.pageType
    this.uploadUrl = Api.uploadUrl()
    this.getDetail()
  },
  methods: {
    getDetail(){
        let configGroupType=this.pageType=='system'?(this.type=='system'?1:2):(this.type=='system'?10:9)
        Api.findConfigByConfigGroup({configGroup:configGroupType}).then(res=>{
            if(res.data.code ==200){
                if(res.data.data && Object.keys(res.data.data).length){
                      if(this.type=='system'){
                    this.itemForm = {...this.itemForm,...res.data.data}
                }else{
                    this.itemForm1 = {...this.itemForm1,...res.data.data}
                }
                }
              
            }
        })
    },
    delApplet(type) {
      this.$set(this.itemForm1, type, '')
    },
    jumpBack(flag) {
      this.$router.back()
    },
    save() {
      if (this.type == 'system') {
        this.saveSys()
      } else {
        this.saveHome()
      }
    },
    saveHome() {
      this.$refs.homeForm.validate((valid) => {
        if (valid) {
          //     if(this.itemForm1.logoImgType == '0'){
          //      this.itemForm1.customIndexLogo= ""
          //  } 
          //   if(this.itemForm1.browserLogoType == '0'){
          //      this.itemForm1.customHtmlHeadLogo = ""
          //  }
          if (
            (this.itemForm1.logoImgType1 == '1' && !this.itemForm1.customIndexLogo) 
          ) {
            this.$message.warning('请上传首页LOGO')
            return
          }
          if (
            (this.itemForm1.browserLogoType == '1' && !this.itemForm1.customHtmlHeadLogo) 
          ) {
            this.$message.warning('请上传浏览器系统图标')
            return
          }

          let params = {
            configGroup: this.pageType=='system'?2:9,
            config: { ...this.itemForm1 }
          }

          Api.saveConfig(params).then((res) => {
               if(res.data.code == 200){
                  this.$message.success('保存成功')
                   this.$router.back()
              }
          })
        }
      })
    },
    saveSys() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          //  if(this.itemForm.loginImgType == '0'){
          //      this.itemForm.customLoginBgimg = ""
          //  } 
          //   if(this.itemForm.logoImgType == '0'){
          //      this.itemForm.customLoginLogo = ""
          //  }
          if (
            (this.itemForm.loginImgType == '1' && !this.itemForm.customLoginBgimg) 
          ) {
            this.$message.warning('请上传登录页背景图')
            return
          }
          if (
            (this.itemForm.logoImgType == '1' && !this.itemForm.customLoginLogo) 
          ) {
            this.$message.warning('请上传登录页LOGO图')
            return
          }
          if (!this.itemForm.baUrl) {
            this.$message.warning('请输入备案链接地址')
            return
          }
   
          let params = {
            configGroup: this.pageType=='system'?1:10,
            config: { ...this.itemForm }
          }

          Api.saveConfig(params).then((res) => {
              if(res.data.code == 200){
                  this.$message.success('保存成功')
                   this.$router.back()
              }
          })
        }
      })
    },
    handleAvatarSuccess(item, response, file, fileList) {
      if (this.type == 'system') {
        this.itemForm[item] = response.data.fileUrl
      } else {
        this.itemForm1[item] = response.data.fileUrl
      }

      this.$forceUpdate()
    },
    checkFile(file, acceptType, maxSize, showMessage) {
      let allowTypes = acceptType.split(',')
      let arr = file.name.split('.')
      let type = arr.length > 1 ? '.' + arr[arr.length - 1].toLowerCase() : undefined
      const isType = allowTypes.indexOf(type) > -1
      const isSize = file.size / 1024 / 1024 < maxSize
      if (type && !isType) {
        if (showMessage) {
          this.$message.error('上传文件格式有误')
        }
        return false
      }
      if (file.size && !isSize) {
        if (showMessage) {
          this.$message.error(`上传文件不能超过${maxSize}MB`)
        }
        return false
      }
      return true
    },
    beforeAvatarUpload(type, file) {
      var accept = '.png,.jpg,.jpeg'
      if (type == 'customLoginBgimg') {
        return this.checkFile(file, accept, 10, true)
      } else {
        return this.checkFile(file, accept, 3, true)
      }

      // return this.checkFile(file, '.png,.jpeg,.jpg', true)
    }
  }
}
</script>
<style scoped lang="less">
.flexCls {
  display: flex;
  align-items: center;
}
.avatar-uploader /deep/ .rs-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/ .rs-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.avatar-uploader /deep/ .rs-button {
  height: 32px;
  line-height: 32px;
  padding: 0;
}
.rs-upload:hover .delCls {
  display: block;
}
.delCls {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  display: none;
}
.delCls i {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button-box {
  position: fixed;
}
.defaultImg {
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
}
.defaultImg img {
  width: 100%;
  height: 100%
}
.imgBox {
  width: 400px;
}
.imgBox img {
  width: 100%;
}
.uploadTip{
    font-size:12px;
}
</style>