var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "50px" } },
    [
      _c("header-title", {
        attrs: { title: _vm.type == "system" ? "登录页配置" : "首页配置" },
      }),
      _c(
        "div",
        [
          _vm.type == "system"
            ? _c(
                "rs-form",
                {
                  ref: "loginForm",
                  staticStyle: { width: "70%" },
                  attrs: {
                    model: _vm.itemForm,
                    "label-width": "140px",
                    "show-message": false,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "rs-form-item",
                    {
                      attrs: {
                        label: "登录页标题：",
                        prop: "customLoginTitle",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flexCls" },
                        [
                          _c("rs-input", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              maxlength: "10",
                              placeholder: "请输入登录页标题",
                            },
                            model: {
                              value: _vm.itemForm.customLoginTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemForm, "customLoginTitle", $$v)
                              },
                              expression: "itemForm.customLoginTitle",
                            },
                          }),
                          _c(
                            "rs-tooltip",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBox",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/customLoginTitle.png"),
                                    },
                                  }),
                                ]
                              ),
                              _c("i", { staticClass: "rs-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "rs-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "登录页背景图：", prop: "loginImgType" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flexCls",
                          staticStyle: { margin: "8px 0" },
                        },
                        [
                          _c(
                            "rs-radio-group",
                            {
                              model: {
                                value: _vm.itemForm.loginImgType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.itemForm, "loginImgType", $$v)
                                },
                                expression: "itemForm.loginImgType",
                              },
                            },
                            [
                              _c("rs-radio", { attrs: { label: "0" } }, [
                                _vm._v("默认"),
                              ]),
                              _c("rs-radio", { attrs: { label: "1" } }, [
                                _vm._v("自定义"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "rs-tooltip",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBox",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/customLoginBgimg.png"),
                                    },
                                  }),
                                ]
                              ),
                              _c("i", { staticClass: "rs-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.itemForm.loginImgType == "1"
                        ? _c(
                            "rs-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                "show-file-list": false,
                                limit: 1,
                                "on-success": _vm.handleAvatarSuccess.bind(
                                  this,
                                  "customLoginBgimg"
                                ),
                                "before-upload": _vm.beforeAvatarUpload.bind(
                                  this,
                                  "customLoginBgimg"
                                ),
                              },
                            },
                            [
                              _vm.itemForm.customLoginBgimg
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.itemForm.customLoginBgimg,
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "rs-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm.itemForm.loginImgType == "1"
                        ? _c("p", { staticClass: "uploadTip" }, [
                            _vm._v(
                              "仅支持上传1张.png、jpg、.jpeg图片，图片尺寸 475x535，大小不超过10M"
                            ),
                          ])
                        : _c("div", { staticClass: "defaultImg" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.itemForm.customLoginBgDefaultImg,
                              },
                            }),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "rs-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "登录页LOGO图：", prop: "logoImgType" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flexCls",
                          staticStyle: { margin: "8px 0" },
                        },
                        [
                          _c(
                            "rs-radio-group",
                            {
                              model: {
                                value: _vm.itemForm.logoImgType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.itemForm, "logoImgType", $$v)
                                },
                                expression: "itemForm.logoImgType",
                              },
                            },
                            [
                              _c("rs-radio", { attrs: { label: "0" } }, [
                                _vm._v("默认"),
                              ]),
                              _c("rs-radio", { attrs: { label: "1" } }, [
                                _vm._v("自定义"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "rs-tooltip",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBox",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/customLoginLogo.png"),
                                    },
                                  }),
                                ]
                              ),
                              _c("i", { staticClass: "rs-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.itemForm.logoImgType == "1"
                        ? _c(
                            "rs-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                "show-file-list": false,
                                "on-success": _vm.handleAvatarSuccess.bind(
                                  this,
                                  "customLoginLogo"
                                ),
                                "before-upload": _vm.beforeAvatarUpload.bind(
                                  this,
                                  "customLoginLogo"
                                ),
                              },
                            },
                            [
                              _vm.itemForm.customLoginLogo
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.itemForm.customLoginLogo,
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "rs-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm.itemForm.logoImgType == "1"
                        ? _c("p", { staticClass: "uploadTip" }, [
                            _vm._v(
                              "仅支持上传1张.png、jpg、.jpeg图片，图片尺寸50x50，大小不超过3M"
                            ),
                          ])
                        : _c("div", { staticClass: "defaultImg" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.itemForm.customLoginDefaultLogo,
                              },
                            }),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "rs-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "备案/许可证号：", prop: "baNo" },
                    },
                    [
                      _c("rs-input", {
                        staticStyle: { width: "80%" },
                        attrs: {
                          maxlength: "128",
                          placeholder: "备案/许可证号",
                        },
                        model: {
                          value: _vm.itemForm.baNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemForm, "baNo", $$v)
                          },
                          expression: "itemForm.baNo",
                        },
                      }),
                      _c("rs-input", {
                        staticStyle: { "margin-top": "15px", width: "80%" },
                        attrs: {
                          maxlength: "256",
                          placeholder: "备案链接地址",
                        },
                        model: {
                          value: _vm.itemForm.baUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemForm, "baUrl", $$v)
                          },
                          expression: "itemForm.baUrl",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type == "home"
            ? _c(
                "rs-form",
                {
                  ref: "homeForm",
                  staticStyle: { width: "70%" },
                  attrs: {
                    model: _vm.itemForm1,
                    "label-width": "160px",
                    "show-message": false,
                    rules: _vm.rules1,
                  },
                },
                [
                  _c(
                    "rs-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "首页标题：", prop: "customIndexTitle" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flexCls" },
                        [
                          _c("rs-input", {
                            attrs: {
                              placeholder: "请输入首页标题",
                              maxlength: "10",
                            },
                            model: {
                              value: _vm.itemForm1.customIndexTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemForm1, "customIndexTitle", $$v)
                              },
                              expression: "itemForm1.customIndexTitle",
                            },
                          }),
                          _c(
                            "rs-tooltip",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBox",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/customIndexTitle.png"),
                                    },
                                  }),
                                ]
                              ),
                              _c("i", { staticClass: "rs-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "rs-form-item",
                    {
                      staticClass: "form-item",
                      attrs: { label: "首页LOGO：", prop: "logoImgType1" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flexCls",
                          staticStyle: { margin: "8px 0" },
                        },
                        [
                          _c(
                            "rs-radio-group",
                            {
                              model: {
                                value: _vm.itemForm1.logoImgType1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.itemForm1, "logoImgType1", $$v)
                                },
                                expression: "itemForm1.logoImgType1",
                              },
                            },
                            [
                              _c("rs-radio", { attrs: { label: "0" } }, [
                                _vm._v("默认"),
                              ]),
                              _c("rs-radio", { attrs: { label: "1" } }, [
                                _vm._v("自定义"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "rs-tooltip",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBox",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/customIndexLogo.png"),
                                    },
                                  }),
                                ]
                              ),
                              _c("i", { staticClass: "rs-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.itemForm1.logoImgType1 == "1"
                        ? _c(
                            "rs-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                "show-file-list": false,
                                "on-success": _vm.handleAvatarSuccess.bind(
                                  this,
                                  "customIndexLogo"
                                ),
                                "before-upload": _vm.beforeAvatarUpload.bind(
                                  this,
                                  "customIndexLogo"
                                ),
                              },
                            },
                            [
                              _vm.itemForm1.customIndexLogo
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.itemForm1.customIndexLogo,
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "rs-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm.itemForm1.logoImgType1 == "1"
                        ? _c("p", { staticClass: "uploadTip" }, [
                            _vm._v(
                              "仅支持上传1张.png、.jpg、.jpeg图片，图片尺寸62x45，大小不超过3M"
                            ),
                          ])
                        : _c("div", { staticClass: "defaultImg" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.itemForm1.customIndexDefaultLogo,
                              },
                            }),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "rs-form-item",
                    {
                      staticClass: "form-item",
                      attrs: {
                        label: "浏览器系统标题：",
                        prop: "customHtmlHeadTitle",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flexCls" },
                        [
                          _c("rs-input", {
                            attrs: {
                              placeholder: "请输入首页标题",
                              maxlength: "10",
                            },
                            model: {
                              value: _vm.itemForm1.customHtmlHeadTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.itemForm1,
                                  "customHtmlHeadTitle",
                                  $$v
                                )
                              },
                              expression: "itemForm1.customHtmlHeadTitle",
                            },
                          }),
                          _c(
                            "rs-tooltip",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBox",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/customHtmlHeadTitle.png"),
                                    },
                                  }),
                                ]
                              ),
                              _c("i", { staticClass: "rs-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "rs-form-item",
                    {
                      staticClass: "form-item",
                      attrs: {
                        label: "浏览器系统图标：",
                        prop: "browserLogoType",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flexCls",
                          staticStyle: { margin: "8px 0" },
                        },
                        [
                          _c(
                            "rs-radio-group",
                            {
                              model: {
                                value: _vm.itemForm1.browserLogoType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.itemForm1,
                                    "browserLogoType",
                                    $$v
                                  )
                                },
                                expression: "itemForm1.browserLogoType",
                              },
                            },
                            [
                              _c("rs-radio", { attrs: { label: "0" } }, [
                                _vm._v("默认"),
                              ]),
                              _c("rs-radio", { attrs: { label: "1" } }, [
                                _vm._v("自定义"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "rs-tooltip",
                            {
                              staticStyle: { "margin-left": "12px" },
                              attrs: { placement: "top" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "imgBox",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/customHtmlHeadLogo.png"),
                                    },
                                  }),
                                ]
                              ),
                              _c("i", { staticClass: "rs-icon-question" }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.itemForm1.browserLogoType == "1"
                        ? _c(
                            "rs-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.uploadUrl,
                                "show-file-list": false,
                                "on-success": _vm.handleAvatarSuccess.bind(
                                  this,
                                  "customHtmlHeadLogo"
                                ),
                                "before-upload": _vm.beforeAvatarUpload.bind(
                                  this,
                                  "customHtmlHeadLogo"
                                ),
                              },
                            },
                            [
                              _vm.itemForm1.customHtmlHeadLogo
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.itemForm1.customHtmlHeadLogo,
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "rs-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          )
                        : _vm._e(),
                      _vm.itemForm1.browserLogoType == "1"
                        ? _c("p", { staticClass: "uploadTip" }, [
                            _vm._v(
                              "仅支持上传1张.png、jpg、.jpeg图片，图片尺寸270x190，大小不超过3M"
                            ),
                          ])
                        : _c("div", { staticClass: "defaultImg" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.itemForm1.customHtmlHeadDefaultLogo,
                              },
                            }),
                          ]),
                    ],
                    1
                  ),
                  _vm.pageType == "system"
                    ? _c(
                        "rs-form-item",
                        {
                          staticClass: "form-item",
                          attrs: {
                            label: "工作台-商户小程序：",
                            prop: "customMerchantImg",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flexCls" },
                            [
                              _c(
                                "rs-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    action: _vm.uploadUrl,
                                    "show-file-list": false,
                                    "on-success": _vm.handleAvatarSuccess.bind(
                                      this,
                                      "customMerchantImg"
                                    ),
                                    "before-upload":
                                      _vm.beforeAvatarUpload.bind(
                                        this,
                                        "customMerchantImg"
                                      ),
                                  },
                                },
                                [
                                  _vm.itemForm1.customMerchantImg
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.itemForm1.customMerchantImg,
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "rs-icon-plus avatar-uploader-icon",
                                      }),
                                  _vm.itemForm1.customMerchantImg
                                    ? _c("div", { staticClass: "delCls" }, [
                                        _c("i", {
                                          staticClass: "rs-icon-delete",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.delApplet(
                                                "customMerchantImg"
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "rs-tooltip",
                                {
                                  staticStyle: { "margin-left": "12px" },
                                  attrs: { placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "imgBox",
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/customMerchantImg.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("i", { staticClass: "rs-icon-question" }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "uploadTip",
                              staticStyle: { "font-size": "12px" },
                            },
                            [
                              _vm._v(
                                "仅支持上传1张.png、jpg、.jpeg图片，图片尺寸125x125，大小不超过3M"
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.pageType == "system"
                    ? _c(
                        "rs-form-item",
                        {
                          staticClass: "form-item",
                          attrs: { label: "工作台-会员小程序：" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flexCls" },
                            [
                              _c(
                                "rs-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    action: _vm.uploadUrl,
                                    "show-file-list": false,
                                    "on-success": _vm.handleAvatarSuccess.bind(
                                      this,
                                      "customAppletVipImg"
                                    ),
                                    "before-upload":
                                      _vm.beforeAvatarUpload.bind(
                                        this,
                                        "customAppletVipImg"
                                      ),
                                  },
                                },
                                [
                                  _vm.itemForm1.customAppletVipImg
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.itemForm1.customAppletVipImg,
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "rs-icon-plus avatar-uploader-icon",
                                      }),
                                  _vm.itemForm1.customAppletVipImg
                                    ? _c("div", { staticClass: "delCls" }, [
                                        _c("i", {
                                          staticClass: "rs-icon-delete",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.delApplet(
                                                "customAppletVipImg"
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "rs-tooltip",
                                {
                                  staticStyle: { "margin-left": "12px" },
                                  attrs: { placement: "top" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "imgBox",
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/customAppletVipImg.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("i", { staticClass: "rs-icon-question" }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "uploadTip",
                              staticStyle: { "font-size": "12px" },
                            },
                            [
                              _vm._v(
                                "仅支持上传1张.png、jpg、.jpeg图片，图片尺寸125x125，大小不超过3M"
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.jumpBack(false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }